
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en",
  "es",
  "fr",
  "it",
  "jp",
  "kr",
  "nl",
  "us"
]

export const localeLoaders = {
  "de": [{ key: "../i18n/lang/de.ts", load: () => import("../i18n/lang/de.ts?hash=fa65fa2d&locale=de" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_i18n_lang_de_ts" */), cache: false }],
  "en": [{ key: "../i18n/lang/en.ts", load: () => import("../i18n/lang/en.ts?hash=f97850f7&locale=en" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_i18n_lang_en_ts" */), cache: false }],
  "es": [{ key: "../i18n/lang/es.ts", load: () => import("../i18n/lang/es.ts?hash=79bd8f58&locale=es" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_i18n_lang_es_ts" */), cache: false }],
  "fr": [{ key: "../i18n/lang/fr.ts", load: () => import("../i18n/lang/fr.ts?hash=fbc592d9&locale=fr" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_i18n_lang_fr_ts" */), cache: false }],
  "it": [{ key: "../i18n/lang/it.ts", load: () => import("../i18n/lang/it.ts?hash=9b8335ef&locale=it" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_i18n_lang_it_ts" */), cache: false }],
  "jp": [{ key: "../i18n/lang/jp.ts", load: () => import("../i18n/lang/jp.ts?hash=d71db94b&locale=jp" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_i18n_lang_jp_ts" */), cache: false }],
  "kr": [{ key: "../i18n/lang/kr.ts", load: () => import("../i18n/lang/kr.ts?hash=51775b3c&locale=kr" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_i18n_lang_kr_ts" */), cache: false }],
  "nl": [{ key: "../i18n/lang/nl.ts", load: () => import("../i18n/lang/nl.ts?hash=7e8f293b&locale=nl" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_i18n_lang_nl_ts" */), cache: false }],
  "us": [{ key: "../i18n/lang/us.ts", load: () => import("../i18n/lang/us.ts?hash=68461cdd&locale=us" /* webpackChunkName: "locale_E_58_E_58_www_shop_nuxt_i18n_lang_us_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "i18n/localeDetector.ts",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de",
      "files": [
        "E:/www/shop_nuxt/i18n/lang/de.ts"
      ]
    },
    {
      "code": "en",
      "files": [
        "E:/www/shop_nuxt/i18n/lang/en.ts"
      ]
    },
    {
      "code": "es",
      "files": [
        "E:/www/shop_nuxt/i18n/lang/es.ts"
      ]
    },
    {
      "code": "fr",
      "files": [
        "E:/www/shop_nuxt/i18n/lang/fr.ts"
      ]
    },
    {
      "code": "it",
      "files": [
        "E:/www/shop_nuxt/i18n/lang/it.ts"
      ]
    },
    {
      "code": "jp",
      "files": [
        "E:/www/shop_nuxt/i18n/lang/jp.ts"
      ]
    },
    {
      "code": "kr",
      "files": [
        "E:/www/shop_nuxt/i18n/lang/kr.ts"
      ]
    },
    {
      "code": "nl",
      "files": [
        "E:/www/shop_nuxt/i18n/lang/nl.ts"
      ]
    },
    {
      "code": "us",
      "files": [
        "E:/www/shop_nuxt/i18n/lang/us.ts"
      ]
    }
  ],
  "defaultLocale": "de",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "i18n/lang",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "files": [
      {
        "path": "E:/www/shop_nuxt/i18n/lang/de.ts"
      }
    ]
  },
  {
    "code": "en",
    "files": [
      {
        "path": "E:/www/shop_nuxt/i18n/lang/en.ts"
      }
    ]
  },
  {
    "code": "es",
    "files": [
      {
        "path": "E:/www/shop_nuxt/i18n/lang/es.ts"
      }
    ]
  },
  {
    "code": "fr",
    "files": [
      {
        "path": "E:/www/shop_nuxt/i18n/lang/fr.ts"
      }
    ]
  },
  {
    "code": "it",
    "files": [
      {
        "path": "E:/www/shop_nuxt/i18n/lang/it.ts"
      }
    ]
  },
  {
    "code": "jp",
    "files": [
      {
        "path": "E:/www/shop_nuxt/i18n/lang/jp.ts"
      }
    ]
  },
  {
    "code": "kr",
    "files": [
      {
        "path": "E:/www/shop_nuxt/i18n/lang/kr.ts"
      }
    ]
  },
  {
    "code": "nl",
    "files": [
      {
        "path": "E:/www/shop_nuxt/i18n/lang/nl.ts"
      }
    ]
  },
  {
    "code": "us",
    "files": [
      {
        "path": "E:/www/shop_nuxt/i18n/lang/us.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
