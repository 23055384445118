import {useI18n} from "vue-i18n";
import numberFormats from "@km/data/numberFormats";

export const initI18n = async (language: string) => {
    console.log('erkannte Sprache:'+language);
    const i18n = useI18n();
    i18n.locale.value = language;
    i18n.fallbackLocale.value = language;
    await i18n.loadLocaleMessages(language);

    for (let numberFormatsKey in numberFormats) {
        i18n.setNumberFormat(numberFormatsKey, numberFormats[numberFormatsKey]);
    }
    return i18n;
}
