<template>
  <div class="shop__popup--wrapper">
    <div class="open-popup-editshop shop__popup">
      <button class="km__button--close" type="button" name="close" @click="$emit('close_popup');"></button>
      <div class="km__popup--content product_colors">
        <div class="edit-colors">
          <h2 class="heading" v-if="product!==null">{{$t("shop_FarbvarianteErstellen")}}</h2>
          <div class="product" v-if="product!==null">
            <div class="product_colors--product">
              <div class="product_colors--image">
                <img :src="getPreviewPicURL" alt="" class="fullwidth">
              </div>
              <div class="product_colors--title">
<!--                <input class="km__input&#45;&#45;standard" type="text" :value="product.nameImShop" @input="changeName($event.target.value)"  />-->
                <span class="product-title">{{product.nameImShop}}</span>
                <span class="product-title">{{product.produkt}}</span>
                <span class="product-feature">{{product.featureStr}}</span>
              </div>
            </div>
            <div class="color" :class="{ 'no-checkbox': product === null }">
              <div class="product_colors--colors">
                <div v-for="(color, index) in zusaetzlicheVarianten" :key="index" class="color-row">
                  <div class="tag-wrapper">
                    <img :src="getFarbvariantenPreview(color)" alt="" class="image-small">
                    <span class="color-element--color" :style="getRGBColor(color)">&nbsp;</span>
                    <span class="color-element--name">{{color.name}}</span>
                    <span v-if="!color.varianteErstellt" class="color-element--btn" @click="createVariant(color)">{{$t("shop_generateColorVariant")}}</span>
                    <input v-else class="color-input" type="checkbox" id="color.pantoneName" value="color.pantoneName" :checked="color.addedToShop" @change="toggleChecked(color)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {useShopItems} from "~/store/shopItemsStore";
import {useShopInfos} from "~/store/shopInfosStore";
import {insertCloudflareDownscalingIntoURL} from "@k/global/globalUtils.js";

export default {
  name: "PopupEditColors",
  props: {
    product: Object
  },
  setup(props, context) {
    const i18n = useI18n();
    const getPreviewPicURL = computed(() => props.product.previewPath + props.product.saveID + "_0.png");
    const shopItems= useShopItems();
    const shopInfos = useShopInfos();

    const zusaetzlicheVarianten = computed(() => props.product.produktFarben.filter(ele => ele.farbvariantenSaveID != props.product.saveID));

    function getRGBColor(color){
      return "background-color: rgb("+color.r+", "+color.g+", "+color.b+")";
    }

    function createVariant(color){
      shopItems.createColorVariant({saveID:props.product.saveID, shopname: shopInfos.shopname, colorPalette: color.palette, colorName: color.pantoneName});
      color.varianteErstellt = true;
      color.addedToShop = true;
    }

    function toggleChecked(color){
      color.addedToShop = !color.addedToShop;
      shopItems.updateColorVariant({shopname: shopInfos.shopname, saveID: props.product.saveID, farbvariantenID: color.farbvariantenID, addedToShop: color.addedToShop})
    }

    function getFarbvariantenPreview(color){
      let url = "https://static.owayo-cdn.com/newhp/img/productHome/artikel/big/productselection-dummy.jpg";
      if(color.previewRendered){
        url = props.product.previewPath + color.farbvariantenSaveID + "_0.png";
        url = insertCloudflareDownscalingIntoURL(url, 40, 40);
      }
      return url;
    }

    return {
      getPreviewPicURL,
      getRGBColor,
      createVariant,
      toggleChecked,
      getFarbvariantenPreview,
      zusaetzlicheVarianten
    }
  }
}
</script>

<style lang="scss" scoped>

:deep(.no-checkbox .color-checkbox) {
  display: none
}

.open-popup-editshop {
  width: 600px;
  max-width: 100%;
}

.product_colors {
  overflow-y: auto;
  height: 800px;
  width: 100%;

  @media (max-width: 540px) {
    height: 400px;
  }
}

.edit-colors {
  width: 100%;

  .product {
    width: 100%;
    margin-bottom: 40px;
  }

  .color {
    width: 100%;
  }
}

.color-element--color{
  width: 25px;
  height: 25px;
  border: 1px solid #cdcdcd;
  display: inline-block;
  top: 10px;
  position: relative;
  padding-left: 10px;
  left: 10px;
}

.color-element--name{
  padding-left: 10px;
  top: 15px;
  position: relative;
  left: 10px;
}

.color-element--btn {
  cursor: pointer;
  color: $color-blue;
  border: 1px solid $color-blue;
  padding: 5px;
  border-radius: 8px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  right: 0;
  top: 10px;
}

.heading {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.product_colors--product{
  display: flex;
}

.product_colors--image {
  width: 100%;
  min-height: 150px;
  padding: 10px;

  @media (max-width: 800px) {
    width: 100%;
  }
}

.product_colors--title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .product-title,
  .product-feature {
    display: block;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
}

.product_colors--colors{
  width: 100%;
}

.color-row {
  position: relative;
  padding-left: 0px;
  border-bottom: 1px solid #CCCCCC;
  height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.color-input {
  cursor: pointer;
  position: absolute;
  right: 60px;
  width: 24px;
  height: 24px;
  border: 1px solid #C0BEBF;
  padding: 5px;
  top: 10px;
}

.image-small{
  width: 40px;
}

.tag-wrapper {
  display: flex;
}

</style>